// import React from 'react';
// import styles from './style.module.css';

function Footer() {
  return null;
  // return (
  //   <div className={styles.Wrapper}>
  //     <div className={`container ${styles.Container}`}>
  //       <div className={styles.Text}>Powered by UBInfo 2019</div>
  //     </div>
  //   </div>
  // );
}

export default Footer;
