const initialState = {
  data: [],
  isLoading: false,
  trendCount: 1000,
  error: false,
  errorMessage: ''
};

const setLoading = action => ({
  isLoading: true,
  error: false,
  errorMessage: ''
});

const setError = action => ({
  isLoading: false,
  error: true,
  errorMessage: action.message
});

const setResponse = action => ({
  isLoading: false
});

const trend = (state = initialState, action) => {
  switch (action.type) {
    case 'TREND_REQUEST': {
      return {
        ...state,
        ...setLoading(action)
      };
    }
    case 'TREND_ERROR': {
      return {
        ...state,
        ...setError(action)
      };
    }
    case 'TREND_RESPONSE': {
      return {
        ...state,
        ...setResponse(action),
        data: action.payload.results ? action.payload.results : []
      };
    }
    case 'TREND_COUNT_REQUEST': {
      return {
        ...state,
        ...setLoading(action)
      };
    }
    case 'TREND_COUNT_ERROR': {
      return {
        ...state,
        ...setError(action)
      };
    }
    case 'TREND_COUNT_RESPONSE': {
      return {
        ...state,
        ...setResponse(action),
        trendCount: action.payload.total
      };
    }
    default: {
      return state;
    }
  }
};

export default trend;
