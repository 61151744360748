import { persistCombineReducers } from 'redux-persist';
import localForage from 'localforage';
import auth from './auth';
import token from './token';
import trend from './trend';
import general from './general';

const config = {
  key: 'root',
  storage: localForage,
  whitelist: ['token', 'general']
};

export default persistCombineReducers(config, {
  auth,
  token,
  trend,
  general
});
