const initialState = {
  user: undefined,
  isLoading: false,
  isLogged: true,
  error: false,
  errorMessage: ''
};

const setLoading = action => ({
  isLoading: true,
  error: false,
  errorMessage: ''
});

const setError = action => ({
  isLoading: false,
  error: true,
  errorMessage: action.message
});

const setResponse = action => ({
  isLoading: false
});

const auth = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_REQUEST': {
      return {
        ...state,
        ...setLoading(action)
      };
    }
    case 'LOGIN_ERROR': {
      return {
        ...state,
        ...setError(action)
      };
    }
    case 'LOGIN_RESPONSE': {
      return {
        ...state,
        ...setResponse(action),
        // isLogged: !!Object.keys(action.payload).length,
        user: action.payload
      };
    }
    case 'LOGGED_REQUEST': {
      return {
        ...state,
        ...setLoading(action)
      };
    }
    case 'LOGGED_ERROR': {
      return {
        ...state,
        ...setError(action)
      };
    }
    case 'LOGGED_RESPONSE': {
      return {
        ...state,
        ...setResponse(action),
        // isLogged: !!Object.keys(action.payload).length,
        user: action.payload
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isLogged: false
      };
    }
    case 'SET_TOKEN': {
      return {
        ...state,
        isLogged: true
      };
    }
    // case 'LOGOUT_REQUEST': {
    //   return {
    //     ...state,
    //     ...setLoading(action)
    //   };
    // }
    // case 'LOGOUT_ERROR': {
    //   return {
    //     ...state,
    //     ...setError(action)
    //   };
    // }
    // case 'LOGOUT_RESPONSE': {
    //   return {
    //     ...state,
    //     ...setResponse(action),
    //     user: null,
    //     isLogged: false
    //   };
    // }
    default:
      return state;
  }
};

export default auth;
