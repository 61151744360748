import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import configureStore from './configureStore';

import './index.css';
import 'react-virtualized/styles.css';
import { App } from './Components';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root');

const { persistor, store } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router basename={process.env.PUBLIC_URL}>
        <Route component={App}></Route>
      </Router>
    </PersistGate>
  </Provider>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
