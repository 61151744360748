import React, { Component } from 'react';
import TimeAgo from 'react-timeago';
import { connect } from 'react-redux';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
// import { IoMdTime } from 'react-icons/io';
import styles from './style.module.css';

const timeStrings = {
  prefixAgo: null,
  prefixFromNow: null,
  suffixAgo: '',
  suffixFromNow: 'from now',
  seconds: 'саяхан',
  minute: 'минутын өмнө',
  minutes: '%d минутын өмнө',
  hour: '1 цагийн өмнө',
  hours: '%d цагийн өмнө',
  day: 'нэг өдрийн өмнө',
  days: '%d өдрийн өмнө',
  month: 'сарын өмнө',
  months: '%d сарын өмнө',
  year: 'жилийн өмнө',
  years: '%d жилийн өмнө',
  wordSeparator: ' '
};

const formatter = buildFormatter(timeStrings);

const toString = string => string.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, '');

const mapStateToProps = state => {
  return {
    general: state.general
  };
};

class Item extends Component {
  render() {
    const { item: data, color } = this.props;

    if (data) {
      const { item } = data;
      return (
        <a
          href={item.link}
          className={`${styles.Wrapper} ${this.props.general.mode === 'Wall' ? styles.Wall : ''}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.Color} style={{ backgroundColor: color }}></div>
          <div className={styles.ImageWrapper}>
            <div className={styles.Image} style={{ backgroundImage: `url(${item.thumb})` }}>
              <div className={styles.Overlay}></div>
              <div className={styles.Channel} style={{ backgroundImage: `url(${item.publisher.image})` }}></div>
            </div>
          </div>
          <div
            className={styles.Body}
            /* style={{
              background: `linear-gradient(to right, #fff, ${color})`
            }} */
          >
            <div className={styles.TextWrapper}>
              <div className={styles.Title}>{item.title}</div>
              <div className={styles.BodyText}>{toString(item.body.content)}</div>
            </div>
            <div className={styles.Date}>
              {/* <IoMdTime className="ItemIcon" /> */}
              <TimeAgo date={item.createdAt} formatter={formatter}></TimeAgo>
            </div>
          </div>
        </a>
      );
    }

    return null;
  }
}

export default connect(mapStateToProps)(Item);
