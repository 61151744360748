const initialState = {
  token: '',
  isLoading: false,
  error: false,
  errorMessage: ''
};

const setLoading = action => ({
  isLoading: true,
  error: false,
  errorMessage: ''
});

const setError = action => ({
  isLoading: false,
  error: true,
  errorMessage: action.message
});

const setResponse = action => ({
  isLoading: false
});

const token = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TOKEN_REQUEST': {
      return {
        ...state,
        ...setLoading(action)
      };
    }
    case 'SET_TOKEN_ERROR': {
      return {
        ...state,
        ...setError(action)
      };
    }
    case 'SET_TOKEN_RESPONSE': {
      return {
        ...state,
        ...setResponse(action),
        token: action.payload.token
      };
    }
    case 'SET_TOKEN': {
      return {
        ...state,
        token: action.payload.token
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        token: ''
      };
    }
    default: {
      return state;
    }
  }
};

export default token;
