import { asyncFn } from '../utils';

const models = {
  list: {
    request: 'TREND_REQUEST',
    error: 'TREND_ERROR',
    response: 'TREND_RESPONSE'
  },
  count: {
    request: 'TREND_COUNT_REQUEST',
    error: 'TREND_COUNT_ERROR',
    response: 'TREND_COUNT_RESPONSE'
  }
};

const list = ({ body }) =>
  asyncFn({
    body,
    url: '/v1/api/trends',
    method: 'GET',
    model: models.list
  });

const count = () =>
  asyncFn({
    body: {},
    url: '/v1/api/trends/count',
    method: 'GET',
    model: models.count
  });

export { list, count };
