import React, { Component } from 'react';
import { Form, Button, Input, Icon, message } from 'antd';
import { connect } from 'react-redux';

import { login } from '../../actions/auth';
import logo from '../../assets/images/logo-app.png';
import styles from './style.module.css';
import './style.css';

const FormItem = Form.Item;

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = {
  login
};

class Login extends Component {
  componentDidMount() {
    if (this.props.auth.isLogged === true) {
      this.props.history.push('/');
    }
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields({ force: true }, async (err, values) => {
      if (!err) {
        await this.props.login({ body: values });

        // if (this.props.auth && this.props.auth.isLogged === true) {
        //   this.props.history.push('/');
        // }

        if (this.props.auth.error) {
          if (this.props.auth.errorMessage === 'Unprocessable Entity') {
            message.error('Нэр эсвэл нууц үг буруу байна!');
          } else {
            message.error('Сервертэй холбогдход алдаа гарлаа!');
          }
        }
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      auth
    } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.top}></div>
        <div className={styles.main}>
          <div className={styles.header}>
            <img alt="" className={styles.logo} src={logo} />
          </div>
          <div className={styles.desc}>UBInfo Feed Dashboard</div>
          <Form onSubmit={this.handleSubmit}>
            <FormItem>
              {getFieldDecorator('username', {
                rules: [
                  {
                    required: true,
                    message: 'Нэвтрэх нэр оруулна уу！'
                  }
                ]
              })(
                <Input
                  size="large"
                  prefix={<Icon type="user" className={styles.prefixIcon} />}
                  placeholder="Нэвтрэх нэр"
                />
              )}
            </FormItem>
            <FormItem>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Нууц үг оруулна уу'
                  }
                ]
              })(
                <Input
                  size="large"
                  prefix={<Icon type="lock" className={styles.prefixIcon} />}
                  type="password"
                  placeholder="Нууц үг"
                />
              )}
            </FormItem>
            <FormItem className={styles.additional}>
              <Button size="large" loading={auth.isLoading} className={styles.submit} type="primary" htmlType="submit">
                Нэвтрэх
              </Button>
            </FormItem>
          </Form>
        </div>
        <div className={styles.footer}>
          Copyright <Icon type="copyright" /> 2019 Slide LLC
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Login));
