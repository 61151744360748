import React, { Component } from 'react';
import { connect } from 'react-redux';
import logo from '../../assets/images/logo-app.png';
import styles from './style.module.css';

const mapStateToProps = state => {
  return {
    general: state.general
  };
};

class Header extends Component {
  clickHandler = e => {
    e.preventDefault();
    this.props.setClicked(true);
  };

  render() {
    return (
      <header className={styles.Wrapper}>
        <div className={`${this.props.general.mode === 'Wall' ? 'containerliquid' : 'container'} ${styles.Container}`}>
          {/* <div className={styles.Icon}> */}
          <a
            className={styles.Icon}
            href="https://ubinfo.mn/app"
            target="_blank"
            rel="noopener noreferrer"
            onClick={this.clickHandler}
          >
            <img src={logo} className={styles.Image} alt="logo" />
            <div className={styles.IconTitle}>Trending news powered by UBInfo</div>
            <div className={styles.SmallIconTitle}>UBInfo</div>
          </a>
          {/* </div> */}
          <div className={styles.Title}></div>
          <div className={styles.Link}>
            <a
              href="https://itunes.apple.com/mn/app/ub-info/id944657007?mt=8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.Download}
                src="https://ubinfo-s3.s3.amazonaws.com/static/app.html/app-store-badge-1-300x89.png"
                alt="apple"
              ></img>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=mn.slide.ubinfo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.Download}
                src="https://ubinfo-s3.s3.amazonaws.com/static/app.html/google-play-badge-300x89.png"
                alt="android"
              ></img>
            </a>
          </div>
        </div>
      </header>
    );
  }
}

export default connect(mapStateToProps)(Header);
