import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Route, Switch } from 'react-router-dom';
import Home from '../Home';
import Login from '../Login';
// import NotFound from '../NotFound';
// import PrivateRoute from '../PrivateRoute';

import { logged } from '../../actions/auth';
import './style.css';

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = {
  logged
};
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: true
    };
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.auth.isLogged === true && this.props.auth.isLogged === false) {
      return 'login';
    }

    if (prevProps.auth.isLogged === false && this.props.auth.isLogged === true) {
      return '/';
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      this.props.history.push(snapshot);
    }
  }

  async componentDidMount() {
    await this.props.logged();
  }

  render() {
    return (
      <Switch>
        <Route exact path={`/login`} component={Login}></Route>
        <Route exact path={`/`} component={Home}></Route>
        <Route component={Home}></Route>
        {/* <PrivateRoute exact path={`/`} component={Home}></PrivateRoute> */}
        {/* <PrivateRoute component={Home} /> */}
      </Switch>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
