import React, { Component } from 'react';
import { connect } from 'react-redux';

import { list, count } from '../../actions/trend';
import { setMode } from '../../actions/general';

import Header from '../Header';
import Feed from '../Feed';
import Footer from '../Footer';
import './style.css';

const mapStateToProps = state => {
  return {
    trend: state.trend.data,
    general: state.general
  };
};

const mapDispatchToProps = {
  getList: list,
  getCount: count,
  setMode: setMode
};

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isClicked: false
    };
  }

  async componentDidMount() {
    await this.props.getCount();
  }

  loadFn = async ({ startIndex, stopIndex }) => {
    await this.props.getList({
      body: {
        start: startIndex,
        end: stopIndex
      }
    });
  };

  setClicked = value => {
    this.setState({
      isClicked: value
    });
  };

  render() {
    return (
      <div className="App">
        <Header setClicked={this.setClicked} />
        <Feed
          loadFn={this.loadFn}
          trend={this.props.trend}
          general={this.props.general}
          setMode={this.props.setMode}
          isClicked={this.state.isClicked}
          setClicked={this.setClicked}
        />
        <Footer />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
