/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import { InfiniteLoader, List } from 'react-virtualized';
import { Skeleton } from 'antd';
import socketIOClient from 'socket.io-client';
import { IoIosDesktop } from 'react-icons/io';
import { MdVideoLabel } from 'react-icons/md';
import Item from '../Item';
import styles from './style.module.css';

const LOADING = 'STATUS_LOADING';
const LOADED = 'STATUS_LOADED';

let socket;

class Feed extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      width: 0,
      list: [],
      listMap: [],
      isMouseMoved: false
    };
  }

  componentDidMount() {
    socket = socketIOClient('/');

    socket.on('trend', this.addItem);
    socket.on('colors', this.setColors);

    const height = this.element.clientHeight;
    const width = this.element.clientWidth;

    this.setState({
      height,
      width
    });
  }

  componentWillUnmount() {
    socket.off('trend', this.addItem);
    socket.off('colors', this.setColors);
  }

  getSnapshotBeforeUpdate(prevProps) {
    return prevProps.isClicked === false && this.props.isClicked === true;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot) {
      if (this.listRef) {
        this.listRef.scrollToRow(0);
        this.props.setClicked(false);
      }
    }
  }

  addItem = data => {
    this.setState(
      {
        listMap: [LOADED, ...this.state.listMap],
        list: [data, ...this.state.list]
      },
      () => {
        this.listRef.forceUpdateGrid();
      }
    );
  };

  setColors = (array = []) => {
    let changeArray = this.state.list.slice(0);

    array.forEach(entry => {
      const index = changeArray.findIndex(item => item.id === entry.id);

      if (index !== -1) {
        changeArray[index].color = entry.color;
        // changeArray[index].color = '#000';
        // changeArray[index].id = entry.id * 10 + 7;
      }
    });

    this.setState(
      {
        list: changeArray
      },
      () => {
        this.listRef.forceUpdateGrid();
      }
    );
  };

  isRowLoaded = ({ index }) => {
    return !!this.state.listMap[index];
  };

  rowRenderer = ({ key, index, style }) => {
    return this.state.listMap[index] === LOADING ? (
      <div key={key} style={style}>
        <div className={`${this.props.general.mode === 'Wall' ? 'containerliquid' : 'container'} ${styles.Container}`}>
          <div className={styles.SkeletonWrapper}>
            {/* <div className={styles.ImagePlaceHolder}></div>
            <div className={styles.Skeleton}>
              <Skeleton></Skeleton>
            </div> */}
            <Skeleton active></Skeleton>
          </div>
        </div>
      </div>
    ) : (
      <div key={key} style={style}>
        <div className={`${this.props.general.mode === 'Wall' ? 'containerliquid' : 'container'} ${styles.Container}`}>
          <Item
            color={this.state.list[index] ? this.state.list[index].color : null}
            item={this.state.list[index]}
          ></Item>
        </div>
      </div>
    );

    // return this.state.listMap[index] === LOADING || true ? (
    //   <div key={key} style={style}>
    //     <div className={`container ${styles.Container}`}>
    //       <Skeleton title="Loading"></Skeleton>
    //     </div>
    //   </div>
    // ) : (
    //   <div key={key} style={style}>
    //     <div className={`container ${styles.Container}`}>
    //       <Item item={this.state.list[index]}></Item>
    //     </div>
    //   </div>
    // );
  };

  setMode = (e, mode) => {
    e.preventDefault();
    this.props.setMode(mode);
  };

  loadMoreRows = async ({ startIndex, stopIndex }) => {
    let loadingObj = [];
    let loadedObj = [];

    for (let i = startIndex; i <= stopIndex; i += 1) {
      loadingObj.push(LOADING);
      loadedObj.push(LOADED);
    }

    this.setState({
      listMap: [...this.state.listMap.slice(0, startIndex), ...loadingObj, ...this.state.listMap.slice(stopIndex + 1)]
    });

    await this.props.loadFn({ startIndex, stopIndex });
    this.setState({
      list: [...this.state.list.slice(0, startIndex), ...this.props.trend, ...this.state.list.slice(stopIndex + 1)],
      listMap: [...this.state.listMap.slice(0, startIndex), ...loadedObj, ...this.state.listMap.slice(stopIndex + 1)]
    });
  };

  scrollTop = e => {
    e.preventDefault();
    this.listRef.scrollToRow(0);
  };

  onMouseMove = e => {
    clearTimeout(this.timer);

    this.setState({
      isMouseMoved: true
    });

    this.timer = setTimeout(() => {
      this.setState({
        isMouseMoved: false
      });
    }, 3000);
  };

  render() {
    return (
      <div className={`${styles.Wrapper}`} ref={element => (this.element = element)} onMouseMove={this.onMouseMove}>
        {/* <a href="" className={styles.New} onClick={e => this.scrollTop(e)}>
          Шинэ мэдээ
        </a> */}
        {/* <div style={{ position: 'absolute', top: 100, right: 100, fontSize: 30, zIndex: 1000000, backgroundColor: 'pink' }}>{JSON.stringify(this.state.isMouseMoved, null, 2)}</div> */}
        <div className={`${styles.Mode} ${this.state.isMouseMoved ? '' : styles.Hidden}`}>
          <a href="" className={`${styles.Desktop}`} onClick={e => this.setMode(e, 'Desktop')}>
            <div className={`${styles.ModeWrapper} ${this.props.general.mode === 'Wall' ? styles.disabled : ''}`}>
              <IoIosDesktop></IoIosDesktop>
              Desktop mode
            </div>
          </a>
          <a href="" className={`${styles.Desktop}`} onClick={e => this.setMode(e, 'Wall')}>
            <div className={`${styles.ModeWrapper} ${this.props.general.mode === 'Desktop' ? styles.disabled : ''}`}>
              <MdVideoLabel></MdVideoLabel>
              Wall mode
            </div>
          </a>
        </div>
        <InfiniteLoader
          className={styles.Loader}
          isRowLoaded={this.isRowLoaded}
          loadMoreRows={this.loadMoreRows}
          rowCount={this.props.trend.trendCount || 1000}
        >
          {({ onRowsRendered, registerChild }) => {
            return (
              <List
                height={this.state.height}
                onRowsRendered={onRowsRendered}
                ref={ref => {
                  this.listRef = ref;
                  registerChild(ref);
                }}
                onScroll={this.scrolled}
                rowCount={this.props.trend.trendCount || 1000}
                rowHeight={this.state.width < 769 ? 120 : this.props.general.mode === 'Wall' ? 143 : 125}
                rowRenderer={this.rowRenderer}
                width={this.state.width}
              ></List>
            );
          }}
        </InfiniteLoader>
      </div>
    );
  }
}

export default Feed;

// const timer = setTimeout(() => {
//   console.log('sssss');
// }, 4000);

// setTimeout(() => {
//   clearTimeout(timer);
//   console.log('zzzzzz');
// }, 1000);
