import { asyncFn } from '../utils';

const models = {
  login: {
    request: 'LOGIN_REQUEST',
    error: 'LOGIN_ERROR',
    response: 'LOGIN_RESPONSE'
  },
  logged: {
    request: 'LOGGED_REQUEST',
    error: 'LOGGED_ERROR',
    response: 'LOGGED_RESPONSE'
  },
  logout: {
    request: 'LOGOUT_REQUEST',
    error: 'LOGOUT_ERROR',
    response: 'LOGOUT_RESPONSE'
  }
};

const login = ({ body }) =>
  asyncFn({
    body,
    // url: '/v1/api/auth/login',
    url: '/v1/api/admin/login',
    method: 'POST',
    model: models.login
  });

const logged = () =>
  asyncFn({
    // url: '/v1/api/users/me',
    url: '/v1/api/admin/me',
    method: 'GET',
    model: models.logged
  });

const logout = () =>
  asyncFn({
    // url: '/v1/api/auth/logout',
    url: '/v1/api/auth/logout',
    method: 'GET',
    model: models.logout
  });

export { login, logout, logged };
