import withQuery from 'with-query';

const request = ({ url: rawUrl, method, body, query, token }) => {
  const url = `${process.env.REACT_APP_API_URL || ''}${rawUrl}`;

  if (method === 'GET') {
    return fetch(withQuery(url, body || query), {
      credentials: 'include',
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (!response.ok) {
        throw new Error(response.status);
      }

      const token = response.headers.get('Token');

      return response.json().then(function(data) {
        return {
          data,
          token
        };
      });
    });
  }
  if (query) {
    return fetch(withQuery(url, query), {
      credentials: 'include',
      method,
      headers: {
        Accept: 'application/json, text/plain, */*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then(response => {
      if (!response.ok) {
        throw new Error(response.status);
      }

      const token = response.headers.get('Token');

      return response.json().then(function(data) {
        return {
          data,
          token
        };
      });
    });
  }
  return fetch(url, {
    credentials: 'include',
    method,
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  }).then(response => {
    if (!response.ok) {
      throw new Error(response.status);
    }

    const token = response.headers.get('Token');

    return response.json().then(function(data) {
      return {
        data,
        token
      };
    });
  });
};

const asyncFn = ({ body, url, method = 'GET', model, name, query }) => async (dispatch, getState) => {
  const {
    token: { token }
  } = getState();

  const payload = {};

  dispatch({
    type: model.request,
    payload,
    name
  });
  try {
    const { data, token: setToken } = await request({
      url,
      method,
      body,
      query,
      token
    });

    if (setToken) {
      dispatch({
        type: 'SET_TOKEN',
        payload: { token: setToken }
      });
    }

    if (!data) {
      throw new Error('no data provided');
    }

    dispatch({
      type: model.response,
      payload: data,
      name
    });
  } catch (error) {
    if (error.message === '403') {
      dispatch({
        type: 'LOGOUT'
      });
    }

    dispatch({
      type: model.error,
      message: error.message,
      name
    });
  }
};

export { request, asyncFn };
