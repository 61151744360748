const initialState = {
  mode: 'Desktop'
};

const general = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MODE': {
      return {
        ...state,
        mode: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export default general;
